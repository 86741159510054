@import url("https://fonts.googleapis.com/css2?family=Anek+Latin:wght@200;400;800&display=swap");
.outlined {
  outline-width: 5px;
  outline-color: #ff0;
  outline-style: solid;
  outline-offset: 10px;
}
body {
  font-family: 'Anek Latin', sans-serif;
  background-color: #cc65b8;
  color: #fff;
  font-weight: 800px;
  margin: 0;
  padding: 5px;
}
.topText {
  position: relative;
  width: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 0 20px;
}
.search_input svg {
  color: #cc65b8;
}
#feedback {
  width: 100%;
  padding: 10px;
  text-align: right;
  background-color: #ba7661;
  color: #fff;
  position: fixed;
  right: 0;
  bottom: 0;
}
#feedback a {
  text-decoration: none;
  color: #fff;
}
#feedback a:hover {
  color: #eb367f;
}
#cookieConsent {
  width: 100%;
  background-color: #ba7661;
  color: #fff;
  font-size: 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 10px;
}
#cookieConsent p {
  margin: 10px 0 0 0;
}
#cookieConsent div {
  float: right;
  height: 38px;
  overflow: hidden;
  margin: 10px 10px;
}
#cookieConsent div div {
  background-color: #eb367f;
  margin-top: -8px;
  font-weight: 600;
  font-size: 24px;
  border-radius: 36px;
  padding: 12px 14px 0 14px;
}
.inputFieldFrame {
  height: 48px;
  overflow: hidden;
  margin: 0 5px;
}
.mealBreakDown {
  margin: 20px 10px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.mealBreakDown div {
  margin: 5px;
}
.mealBreakDown input {
  font-family: 'Anek Latin', sans-serif;
  color: #fff;
  text-align: center;
  background-color: #eb367f;
  outline: none;
  border: 1px #eb367f solid;
}
.mealBreakDown :focus {
  outline: none;
  background-color: #e59186;
}
@media only screen and (max-width: 599px) {
  .mealBreakDown input {
    margin-top: -10px;
    font-weight: 600;
    font-size: 32px;
    border-radius: 36px;
  }
  .mealBreakDown .inputFieldFrame {
    height: 36px;
    margin: 0 5px;
  }
}
@media only screen and (min-width: 600px) {
  .mealBreakDown input {
    margin-top: -12px;
    font-weight: 800;
    font-size: 48px;
    border-radius: 36px;
  }
  .mealBreakDown .inputFieldFrame {
    height: 48px;
    margin: 0 5px;
  }
}
.flexCenter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.search_input {
  position: relative;
  margin: 20px auto;
  width: 560px;
  max-width: 90%;
  font-size: 20px;
}
.search_input input {
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  border-radius: 28px;
  color: #eb367f;
  margin-top: 5px;
  font-family: 'Anek Latin', sans-serif;
  border: 1px #fff solid;
}
label {
  display: block;
  text-align: center;
}
input {
  background-color: #fff;
  font-family: 'Anek Latin', sans-serif;
  text-align: right;
  padding: 8px 12px;
  border: 2px #fff solid;
  border-radius: 6px;
  outline: none;
}
input :focus {
  background-color: #fff;
  outline: 4px #ba7661 solid;
}
table {
  border-collapse: collapse;
}
table td,
table th {
  border: 1px solid #fff;
  padding: 4px 12px;
  font-family: 'Anek Latin', sans-serif;
}
table td:not(:first-child) {
  background-color: #eb367f;
  color: #fff;
  text-align: right;
}
table td:first-child {
  background-color: #eb367f;
  color: #fff;
  text-align: right;
}
#detail {
  background-color: #fff;
  border: 2px solid #e59186;
  position: absolute;
  width: 180px;
}
#detail tr td:first-child {
  text-align: left;
}
#detail td:first-child {
  width: 90px;
}
#detail td:not(:first-child) {
  width: 60px;
}
#detail h2 {
  text-align: center;
  font-weight: 800;
  font-size: 16px;
  color: #cc65b8;
}
#detail >small {
  color: #cc65b8;
}
#detail small {
  font-size: 12px;
}
#detail a {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  pointer-events: all;
}
#detail *:not(a) {
  pointer-events: none;
}
.recipeTable th {
  background-color: #ba7661;
  color: #fff;
}
.recipeTable td:first-child {
  width: 800px;
  cursor: crosshair;
}
.recipeTable td:not(:first-child) {
  width: 200px;
}
.recipeTable tr:last-child td {
  background-color: #eb367f;
  color: #fff;
  text-align: right;
  font-weight: bold;
  width: 200px;
}
.recipeTable tr:first-child th {
  font-weight: normal;
  text-align: center;
  font-size: 12px;
  width: 200px;
}
.recipeTable tr:first-child th b {
  font-size: 24px;
  color: #fff;
}
@media only screen and (max-width: 599px) {
  .recipeTable {
    margin: 20px;
    width: 100%;
  }
  .recipeTable td:nth-child(n+3) {
    display: none;
  }
  .recipeTable th:nth-child(n+3) {
    display: none;
  }
}
@media only screen and (max-width: 599px) {
  .recipeTable {
    margin: 0;
  }
  .recipeTable td:first-child {
    width: 800px;
  }
}
.suggestion {
  display: inline-block;
  border: solid 1px #eb367f;
  background-color: #eb367f;
  color: #fff;
  cursor: crosshair;
}
@media only screen and (max-width: 599px) {
  .suggestion {
    padding: 4px 8px;
    border: solid 1px #eb367f;
    border-radius: 8px;
    margin: 8px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) {
  .suggestion {
    padding: 8px 12px;
    border: solid 1px #eb367f;
    border-radius: 12px;
    margin: 10px;
  }
}
